import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import ScrollToTop from "react-scroll-to-top";
import AllProductPage from "./pages/AllProductPage";
import ArchivioInstallazioniPage from "./pages/ArchivioInstallazioniPage";
import ProductDetailsPage from "./components/ProductDetails";
import BlogPage from './pages/BlogPage';
import ContattiPage from './pages/ContactPage';
import InformativaPrivacy from './pages/InformativaPrivacy';
import NoleggioOperativo from "./pages/NoleggioOperativoPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ConsulenzaEnergeticaPage from "./pages/ConsulenzaEnergeticaPage";
import AboutPage from "./pages/AboutPage";
import NewsPage from "./pages/NewsPage";
import NewsDetailsPage from './pages/NewsDetailsPage';
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import AuthProvider from "./provider/authProvider";
import ProductManagerPage from "./pages/ProductManagerPage";
import CategoryManagerPage from "./pages/CategoryManagerPage";
import SubCategoryManagerPage from "./pages/SubCategoryManagerPage";
import BlogManagerPage from "./pages/BlogManagerPage";
import BrandManagerPage from "./pages/BrandManagerPage";
import NewsManagerPage from "./pages/NewsManagerPage";
import BlogTagManagerPage from "./pages/BlogTagManagerPage";
import UserManagerPage from "./pages/UserManagerPage";
import RateizzazioniPage from "./pages/RateizzazioniPage";
import { useEffect,useState } from "react";
import { CookieConsent } from "react-cookie-consent";
import { useNavigate } from "react-router-dom";

function App() {
  // const navigate = useNavigate();
  // State to track cookie preferences
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [analyticsAccepted, setAnalyticsAccepted] = useState(true); // Default to true for analytics cookies
  const [showCookieManager, setShowCookieManager] = useState(false); // state to toggle cookie management

  // Function to set cookies using document.cookie
  const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/; max-age=31536000`; // max-age 1 year
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  // Handle user consent
  const handleAccept = () => {
    setCookie("cookieConsent", "true");
    setCookie("analyticsConsent", analyticsAccepted ? "true" : "false");
    setCookiesAccepted(true);
  };

  const handleDecline = () => {
    setCookie("cookieConsent", "true");
    setCookie("analyticsConsent", "false");
    setCookiesAccepted(true);
    setAnalyticsAccepted(false);
  };

  const handleManageCookies = () => {
    setShowCookieManager(true); // Show the cookie management modal
  };

  const handleToggleAnalytics = () => {
    setAnalyticsAccepted(!analyticsAccepted);
    setCookie("analyticsConsent", !analyticsAccepted ? "true" : "false");
  };

  const handleResetCookies = () => {
    // Reset the cookies to show the banner again
    deleteCookie("lediamoluceUserConsent"); // Delete the cookie used by react-cookie-consent
    deleteCookie("analyticsConsent");  // Delete the analytics consent cookie
    setCookiesAccepted(false); // Reset state to show banner
    setAnalyticsAccepted(true); // Reset analytics consent to default "true"
  };

  useEffect(() => {
    // Check if cookies are already accepted by checking cookies
    const storedConsent = document.cookie.split("; ").find(row => row.startsWith("lediamoluceUserConsent"));
    const storedAnalytics = document.cookie.split("; ").find(row => row.startsWith("analyticsConsent"));

    if (storedConsent) {
      setCookiesAccepted(true);
    }

    if (storedAnalytics) {
      setAnalyticsAccepted(storedAnalytics.split("=")[1] === "true");
    }
  }, []);
  const protectedChildren = {path:"/",element:<AdminPage />}
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <ScrollToTop smooth color="#A847F0" />
      <AuthProvider>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/prodotti" element={<AllProductPage />} />
        <Route exact path="/installazioni" element={<ArchivioInstallazioniPage />} />
        {/* <Route exact path="/clienti" element={<ArchivioInstallazioniPage />} /> */}
        <Route path="/dettagli_prodotto/:id" element={<ProductDetailsPage />}/>
        <Route exact path="/blog" element={<BlogPage />}/>
        <Route path="/dettagli_blog/:id" element={<BlogDetailsPage />}/>
        <Route exact path="/contatti" element={<ContattiPage />}/>
        <Route exact path="/informativa_privacy" element={<InformativaPrivacy />}/>
        {/* <Route exact path="/noleggio_operativo" element={<NoleggioOperativo />}/> */}
        <Route exact path="/consulenza_energetica" element={<ConsulenzaEnergeticaPage />}/>
        <Route exact path="/chi_siamo" element={<AboutPage />}/>
        <Route exact path="/rateizzazioni" element={<RateizzazioniPage />}/>
        <Route exact path="/news" element={<NewsPage />}/>
        <Route  path="/news/:id" element={<NewsDetailsPage />}/>
        <Route  exact path="/login" element={<LoginPage />}/>
        <Route exact path="/admin" element={<AdminPage />} />

        <Route path="/admin/prodotto/:id" element={<ProductManagerPage />} />
        <Route exact path="/admin/prodotto" element={<ProductManagerPage />} />
        
        <Route path="/admin/categoria/:id" element={<CategoryManagerPage />} />
        <Route exact path="/admin/categoria" element={<CategoryManagerPage />} />
        <Route path="/admin/categoria/:id/:id_sub" element={<SubCategoryManagerPage />} />
        
        <Route path="/admin/brand/:id" element={<BrandManagerPage />} />
        <Route exact path="/admin/brand" element={<BrandManagerPage />} />
        
        <Route path="/admin/blog/:id" element={<BlogManagerPage />} />
        <Route exact path="/admin/blog" element={<BlogManagerPage />} />
        <Route path="/admin/blogtag/:id" element={<BlogTagManagerPage />} />
        <Route path="/admin/blogtag" element={<BlogTagManagerPage />} />

        <Route path="/admin/news/:id" element={<NewsManagerPage />} />
        <Route exact path="/admin/news" element={<NewsManagerPage />} />
        <Route path="/admin/utente" element={<UserManagerPage />} />
        
        {/* <Route exact path="/index-two" element={<HomePageTwo />} />
        <Route exact path="/all-product" element={<AllProductPage />} />
        <Route exact path="/product-details" element={<ProductDetailsPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />
        <Route exact path="/cart" element={<CartPage />} />
        <Route exact path="/cart-personal" element={<CartPersonalPage />} />
        <Route exact path="/cart-payment" element={<CartPaymentPage />} />
        <Route exact path="/cart-thank-you" element={<CartThankYouPage />} />
        <Route exact path="/dashboard" element={<DashboardPage />} />
        <Route exact path="/dashboard-profile" element={<DashboardProfilePage />} />
        <Route exact path="/follower" element={<FollowerPage />} />
        <Route exact path="/following" element={<FollowingPage />} />
        <Route exact path="/setting" element={<SettingPage />} />
        <Route exact path="/statement" element={<StatementPage />} />
        <Route exact path="/earning" element={<EarningPage />} />
        <Route exact path="/review" element={<ReviewPage />} />
        <Route exact path="/download" element={<DownloadPage />} />
        <Route exact path="/refund" element={<RefundPage />} />
        <Route exact path="/blog" element={<BlogPage />} />
        <Route exact path="/blog-details" element={<BlogDetailsPage />} />
        <Route exact path="/blog-details-sidebar" element={<BlogDetailsSidebarPage />} />
        <Route exact path="/contact" element={<ContactPage />} />

        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/login" element={<LoginPage />} /> */}

      </Routes>
      {/* The Cookie Consent Banner */}
      {!cookiesAccepted && (
        <CookieConsent
          location="bottom"
          style={{
            background: "#161c2d",
            color: "#ffffff",
            padding: "20px",
            fontSize: "16px",
          }}
          buttonStyle={{
            background: "#008bd2",
            color: "black",
            borderRadius: "5px",
            padding: "10px 20px",
            fontWeight: "bold",
          }}
          cookieName="lediamoluceUserConsent"  // This is the cookie name that the component uses
          expires={1} // Cookie expires in 1 day
          buttonText="Accetto"
          declineButtonText="Declino"
          declineButtonStyle={{
            background: "#008bd2",
            color: "#ffffff",
            borderRadius: "5px",
            padding: "10px 20px",
            fontWeight: "bold",
          }}
          enableDeclineButton={false} // Hide the decline button, we'll manage cookies manually
          onAccept={handleAccept}
          onDecline={handleDecline}
        >
          <span>
            Questo sito utilizza dei cookie essenziali per migliorare l'esperienza utente.{" "}
            <a href="/informativa_privacy" style={{ color: "#008bd2" }}>
              Scopri di più
            </a>
          </span>

          {/* Button to manage cookie preferences inside the banner */}
          <div
            onClick={handleManageCookies}
            style={{
              marginTop: "10px",
              cursor: "pointer",
              color:"#008bd2",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Gestisci preferenze cookie
          </div>
        </CookieConsent>
      )}

      {/* "Gestisci preferenze cookie" button outside the banner */}
      {cookiesAccepted && (
        <div
          onClick={handleResetCookies}
          style={{
            position: "fixed",
            bottom: "10px",
            right: "5%",
            background: "rgba(0,0,0,0.7)",
            color: "#ffffff",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            zIndex: 1000,
          }}
        >
          Reimposta preferenza cookie
        </div>
      )}

      {/* Cookie Preferences Modal */}
      {showCookieManager && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            borderRadius: "5px",
            zIndex: 1001,
          }}
        >
          <h3>Gestisci le tue preferenze sui cookie</h3>

          {/* Essential Cookies - Always enabled, cannot be toggled */}
          <div style={{ marginBottom: "10px", color: "#555" }}>
            <label>
              <input
                type="checkbox"
                checked={true}
                disabled
              />
              Cookie essenziali (sempre abilitati)
            </label>
          </div>

          {/* Analytics Cookies - Can be toggled */}
          {/* <div>
            <label>
              <input
                type="checkbox"
                checked={analyticsAccepted}
                onChange={handleToggleAnalytics}
              />
              Accetta cookie analitici
            </label>
          </div> */}

          <div style={{ marginTop: "20px" }}>
            <button
              onClick={() => setShowCookieManager(false)} // Close the modal
              style={{
                backgroundColor: "#008bd2 ",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Chiudi
            </button>
          </div>
        </div>
      )}
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
